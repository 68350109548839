<template>
  <section id="detail-kegiatan">
    <b-row>
      <b-col
        lg="3"
        cols="12"
      >
        <kegiatan-about :detail-program="dataProgram" />
      </b-col>

      <b-col
        lg="9"
        cols="12"
      >
        <app-collapse type="margin">
          <app-collapse-item
            :title="(componentType === 'APP') ? 'Aplikasi' : 'Infrastruktur'"
            :is-visible="true"
          >
            <template v-if="componentType === 'APP'">
              <kegiatan-aplikasi :data-aplikasi="dataProgram" />
            </template>
            <template v-else>
              <kegiatan-infrastruktur :data-infra="dataProgram" />
            </template>
          </app-collapse-item>

          <app-collapse-item
            title="Rekomendasi Sementara"
            :is-visible="true"
          >
            <h4 class="text-danger pt-1 border-top-dark">
              Tidak Dilanjutkan
            </h4>
            <b-list-group
              flush
            >
              <b-list-group-item disabled>
                Rekomendasi A
              </b-list-group-item>
              <b-list-group-item disabled>
                Rekomendasi B
              </b-list-group-item>
              <b-list-group-item disabled>
                Rekomendasi C
              </b-list-group-item>
              <b-list-group-item disabled>
                Rekomendasi D
              </b-list-group-item>
            </b-list-group>
          </app-collapse-item>

          <app-collapse-item
            title="Konfirmasi"
            :is-visible="true"
          >
            <h4 class="pt-1 border-top-dark">
              Nama K/L Di Sini: <span class="text-warning">Tidak Setuju</span>
            </h4>
            <p>{{ dataProgram.update_ts | formatDate }}</p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sagittis leo ut
              tellus porta commodo. Sed rutrum vehicula turpis, ac pulvinar metus vestibulum sit
              amet. Nullam arcu ante, auctor porta interdum nec, accumsan in augue. Proin pharetra
              fermentum urna, at ornare leo gravida dapibus. Nam nec augue condimentum, convallis
              tortor suscipit, gravida purus. Cras ornare urna ut tortor egestas, vitae accumsan
              quam malesuada. Etiam ac porttitor lorem, placerat iaculis justo.
            </p>
            <p>Dokumen Tambahan :</p>
            <p>
              <b-button
                variant="success"
                size="sm"
                class="mr-1"
                @click="openKomponen('../data-source/contoh_dokumen.pdf')"
              >View</b-button>
              <b-button
                href="#"
                variant="info"
                size="sm"
              >
                download
              </b-button>
            </p>
          </app-collapse-item>

          <app-collapse-item
            title="Hasil Pembahasan Peninjauan Kembali"
            :is-visible="true"
          >
            <b-form-group
              label="Rangkuman Hasil Pembahasan"
              label-for="vi-rangkuman"
            >
              <validation-provider
                #default="{ errors }"
                name="Rangkuman Hasil Pembahasan"
                rules="required"
              >
                <b-form-textarea
                  id="vi-rangkuman"
                  v-model="rangkuman"
                  rows="4"
                  required
                  name="rangkuman"
                  placeholder="Rangkuman Hasil Pembahasan"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <modul-rekomendasi-clearance />

            <div class="py-1">
              <b-button
                variant="success"
              >
                Simpan Hasil Pembahasan
              </b-button>
            </div>
          </app-collapse-item>
        </app-collapse>

      </b-col>

    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BButton, BRow, BCol, BFormGroup, BFormTextarea, BListGroup, BListGroupItem, BCardText,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import VuePdfApp from 'vue-pdf-app'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import ModulRekomendasiClearance from './ModulRekomendasiClearance.vue'
import KegiatanAbout from '../kegiatan/detail-kegiatan/KegiatanAbout.vue'
import KegiatanAplikasi from '../kegiatan/detail-kegiatan/KegiatanAplikasi.vue'
import KegiatanInfrastruktur from '../kegiatan/detail-kegiatan/KegiatanInfrastruktur.vue'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormTextarea,
    BCardText,
    ValidationProvider,

    KegiatanAbout,
    KegiatanAplikasi,
    KegiatanInfrastruktur,
    ModulRekomendasiClearance,
    VuePdfApp,
  },
  data() {
    return {
      userData: getUserData(),
      componentType: this.$route.params.type,
      app_id: this.$route.params.id,
      verifikasi_description: '',
      rangkuman: '',
      fileDoc: '',
      selected: '',
      dataProgram: {
        sumber_dana: { name: '' },
        jenis_belanja: { name: '' },
        jenis_pengadaan: { name: '' },
        file_kak: { file_url: '' },
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.id || !to.params.type) {
      next('/')
    } else {
      next()
    }
  },
  mounted() {
    const appType = (this.$route.params.type === 'APP') ? 'aplikasi' : 'infrastruktur'
    // check clearance program data
    this.$http.get(`/clearances/${appType}/${this.app_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
    })
      .then(res => {
        this.dataProgram = res.data.data
      })
  },
  methods: {
    openKomponen(value) {
      this.fileDoc = value
      this.$refs['modal-komponen'].show()
    },
  },
}
</script>
